<template>
  <UserSingleCard>
    <template v-slot:title>
      <p class="reset-password-title">パスワード再設定画面</p>
    </template>

    <template v-slot:description>
      <p class="reset-password-description">
        新しいパスワードを入力し送信ボタンを押してください。
      </p>
    </template>

    <template v-slot:contents>
      <p class="reset-password-password-title">新しいパスワード</p>
      <input class="password" type="password" v-model="password" />

      <p class="reset-password-password-confirm-title">新しいパスワード（確認用）</p>
      <input class="password" type="password" v-model="passwordConfirm"/>
    </template>

    <template v-slot:button>
      <div class="button-wrapper">
        <WideButton
          :label="'パスワード再設定'"
          :disabled="disabled"
          @click="$_resetPassword"
        />
        <WideButton
          :label="'ログインに戻る'"
          :backgroundColor="'#707070'"
          @click="$router.push('/admin/login')"
        />
      </div>
    </template>
  </UserSingleCard>
</template>

<script>
import WideButton from '@/components/atoms/common/AppWideButton'
import UserSingleCard from '@/templates/admin/AppAdminSingleCard'
import { showInfoPopupAlert, showErrorPopupAlert } from '@/helper/common'
import { resetPassword } from '@/helper/auth'
// import {
//   auth,
//   verifyPasswordResetCode,
//   confirmPasswordReset,
// } from '@/firebase/config'

/**
 * 現状はFirebaseのアクションURLが１つしか指定できないので、admin側のコンポーネントで対応している
 * FIXME: pageに具体的なコンポーネントの配置を記述するのではなく、templateに移すこと
 */
export default {
  name: 'TheResetPassword',
  components: {
    UserSingleCard,
    WideButton
  },
  data: function () {
    return {
      password: '',
      passwordConfirm: '',
      // message: '',
      // messageType: '',
      // mode: '',
      actionCode: ''
    }
  },
  watch: {
    // message: function () {
    //   if (this.messageType === 'info') {
    //     showInfoPopupAlert(
    //       this.message,
    //       true,
    //       {},
    //     )
    //   } else if (this.messageType === 'error') {
    //     showErrorPopupAlert(this.message)
    //   }
    // }
  },
  mounted() {
    // this.mode = this.$_getParameterByName("mode")
    this.actionCode = this.$_getParameterByName("oobCode")
  },
  computed: {
    disabled() {
      return (
        this.password &&
        this.passwordConfirm &&
        (this.password !== this.passwordConfirm)
      )
    }
  },
  methods: {
    $_getParameterByName(name) {
      const url = window.location.href
      const cleanName = name.replace(/\[\]/g, "\\$&")
      const regex = new RegExp("[?&]" + cleanName + "(=([^&#]*)|&|#|$)")
      const results = regex.exec(url)
      if (!results) return null
      if (!results[2]) return ''
      return decodeURIComponent(results[2].replace(/\+/g, " "))
    },
    $_resetPassword: async function () {
      this.$store.dispatch('loadingMask/showLoadingMask')
      try {
        await resetPassword(this.actionCode, this.password)
        // const email = await verifyPasswordResetCode(auth, this.actionCode)
        // await confirmPasswordReset(auth, this.actionCode, this.password)

        // this.messageType = "info"
        // this.message = 'パスワードを再設定しました。'
        this.$_showPopup('info', 'パスワードを再設定しました。')
        this.$router.push('/user/login')
      } catch (e) {
        // this.messageType = "error"
        this.$_showPopup('info', this.$_getErrorMessage(e.message))
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    $_getErrorMessage(message) {
      if (message.includes("expired-action-code")){
        return "パスワードリンクの有効期限が切れました。<br />再度パスワード再設定メールを送信してください。"
      } else if (message.includes("invalid-action-code")){
        return "パスワードリンクが不正です。<br />再度パスワード再設定メールを送信してください。"
      } else if (message.includes("user-disabled")){
        return "対象アカウントが無効化されています。<br />管理者にお問い合わせください。"
      } else if (message.includes("user-not-found")){
        return "入力されたメールアドレスに一致するアカウントはみつかりませんでした。"
      } else {
        return message
      }
    },
    $_showPopup(type, message) {
      if (type === 'info') {
        showInfoPopupAlert(
          message,
          true,
          {},
          this.callback
        )
      } else if (type === 'error') {
        showErrorPopupAlert(message)
      }
    }
  },
}
</script>

<style lang="scss">
.reset-password-description {
  margin: 32px 0 16px 0;
}
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 4px;
}
.reset-password-title {
  margin: 16px 0;
}
.reset-password-password-title {
  text-align: left;
  margin-bottom: 8px;
  font-size: 12px;
}
.reset-password-password-confirm-title {
  text-align: left;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
}
.password {
  margin: 0;
}
.button-wrapper {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
</style>
