import {
  auth, signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  verifyPasswordResetCode,
  confirmPasswordReset,
  sendEmailVerification
} from '@/firebase/config'
import { replaceLikes } from '@/helper/firestore/likes'
import { postUsers, getUser, updateLogInDate } from '@/helper/firestore/user'
import store from '@/store/index.js'

export const logInApp = async (email, password) => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        try {
          if (!userCredential.user.emailVerified) {
            await logOutApp()
            throw new Error('メールアドレスが認証されていません。')
          }
          await updateLogInDate(userCredential.user.uid)
          const user = await getUser(userCredential.user.uid)
          store.dispatch('user/setUser', user)
        } catch(e) {
          throw new Error(e)
        }

        /**
         * 暫定処置なので、一定の移行期間を考慮後に削除する
         * storeのbookmark系も同様に削除
         * ローカルのお気に入り情報をリモートに移動させる
         * ※ キャッシュクリアの前にやらないといけない
         * 1. ストアのお気に入りの有無をチェック
         * 2. 1があればリモートのuserコレクションに書き込み
         * 3. 1があればストアのuser.likesに書き込み
         * 4. 1を削除（これで移行完了とし、次回以降処理がスキップされる）
         */
        const bookmarkedPlaceIds = localStorage.getItem('bookmarkedPlaceIds')
        if (bookmarkedPlaceIds && bookmarkedPlaceIds.length) {
          // localStorageに保持されている値は配列ではなく','で連結された
          // 文字列となっているため
          const idArray = bookmarkedPlaceIds.split(',')
          try {
            await replaceLikes(userCredential.user.uid, idArray)
            store.dispatch('user/replaceLikes', idArray)
          } catch(e) {
            throw new Error(e)
          }
        }

        // User loged in
        resolve(userCredential.user)
      })
      .catch((e) => reject(e))
  })
}

export const logOutApp = async () => {
  try {
    store.dispatch('user/resetState')
    await signOut(auth)
  } catch (e) {
    throw new Error(e)
  }
}

export const signUpApp = (email, password) => {
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        await postUsers(
          userCredential.user.uid,
          {
            email: userCredential.user.email,
            likes: [],
            status: 0,
            type: 0
          }
        )
        sendEmailVerification(auth.currentUser)
          .then(() => {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            store.dispatch('user/setUser', { email: email })
            resolve()
          })
          .catch((e) => reject(e))
      })
      .catch((e) => reject(e))
    })
}

export const resetPassword = async (actionCode, newPassword) => {
  try {
    await verifyPasswordResetCode(auth, actionCode)
    await confirmPasswordReset(auth, actionCode, newPassword)
  } catch (e) {
    throw new Error(e)
  }
}