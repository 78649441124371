import common from './common'
import { USERS_DOCS_NAME } from '@/constants/firestore'
import { isEmptyObject } from '@/helper/util'

/**
 * 特定ユーザー取得
 * @param {String} usersId 
 * @return {Array.<Object>} ユーザー配列
 */
const getUser = async usersId => {
  try {
    if (!usersId) throw new Error('Users document ID is not provided.')  
    return await common.get(USERS_DOCS_NAME, usersId)
  } catch (e) {
    throw new Error(`getUsers => ${e}`)
  }
}

/**
 * userssコレクションの全ユーザードキュメント取得
 * @return {Array.<Object>} ユーザー配列
 */
const getAllUsers = async () => {
  try {
    return await common.get(USERS_DOCS_NAME)
  } catch (e) {
    throw new Error(`getAllUsers => ${e}`)
  }
}

/**
 * 特定Emailのユーザー取得
 * @param {String} email
 * @return {Array.<Object>} ユーザー配列
 */
const getUserByEmail = async email => {
  try {
    if (!email) throw new Error('Email is not provided.')  
    return await common.getUserByEmail(email)
  } catch (e) {
    throw new Error(`getUserByEmail => ${e}`)
  }
}

/**
 * ユーザー更新
 * @param {*} value 更新対象のユーザーIDを以下の形式で持つこと
 * { ..., userId: xxx }
 * @returns {Object|Array.<Object>} 更新結果
 */
const putUsers = async (value) => {
  try {
    if (isEmptyObject(value)) throw new Error('Value is not provided.')
    if (!value.userId) throw new Error('Users document ID is not provided.')

    return await common.put(USERS_DOCS_NAME, value.userId, value)
  } catch (e) {
    throw new Error(`putUsers => ${e}`)
  }
} 

/**
 * ユーザー登録処理
 * @param {Object} value 
 * @return {Object} 処理結果
 */
const postUsers = async (id, value) => {
  try {
    if (isEmptyObject(value)) throw new Error('Value is not provided.')  
    return await common.postUser(id, value)
  } catch (e) {
    throw new Error(`postUsers => ${e}`)
  }
} 

/**
 * ユーザー削除処理
 * @param {String} userId 
 * @returns {Object} 成功時オブジェクト({ statue: 'success' })
 */
const deleteUsers = async (userId)  => {
  try {
    if (!userId) throw new Error('Users document ID is not provided.')  
    return await common.delete(USERS_DOCS_NAME, userId)
  } catch (e) {
    throw new Error(`deleteUsers => ${e}`)
  }
}

/**
 * ログイン日時を記録
 * @param {String} userId
 * @returns 
 */
const updateLogInDate = async (userId) => {
  try {
    if (!userId) throw new Error('Users document ID is not provided.')  
    return await common.updateLogInDate(userId)
  } catch (e) {
    throw new Error(`updateLogInDate => ${e}`)
  }
}

export {
  getUser,
  getAllUsers,
  getUserByEmail,
  putUsers,
  postUsers,
  deleteUsers,
  updateLogInDate
}